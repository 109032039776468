.ss-content {
    overflow-x: hidden !important;
  }
  .event-name {
    font-size: 16px !important;
    line-height: 16px !important;
  }
  .event-name.event-pretended-link:hover {
    color: #f6a365 !important;
  }
  .event-date {
    font-size: 20px !important;
    line-height: 20px !important;
  }
  
  .event-buy-btn {
    height: 40px !important;
    font-size: 16px !important;
    line-height: 16px !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 16px !important;
  }
  .event-content-wraper {
    padding-bottom: 50px !important;
  }
  .event-wrapper {
    padding: 1rem;
    border: none !important;
    border-radius: 16px !important;
    margin: 8px !important;
    max-width: calc(33% - 15px) !important;
    box-shadow: 1px 1px 3px #efefef !important;
    border: 1px solid #efefef !important;
  }
  @media (max-width: 768px) {
    .event-wrapper {
      max-width: unset !important;
    }
  }
  
  /* .event-wrapper:nth-child(odd) {
    background-color: #f6a365 !important;
  }
  .event-wrapper .event-buy-btn {
    background-color: #fafafa !important;
    color: #444444 !important;
  }
  .event-wrapper:nth-child(even) {
    background-color: #84bf62 !important;
  }
  .event-wrapper:nth-child(odd):hover {
    background-color: #fd9e56 !important;
  }
  .event-wrapper:nth-child(even):hover {
    background-color: #7cc94f !important;
  }
  .event-logo-box {} */
  